import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo'
import { useUrl } from '../fragments/hooks/useUrl'
import HeroComponent from '../components/HeroComponent/HeroComponent'
import SectionSitemap from '../components/SectionSitemap/SectionSitemap'
import Breadcrum from '../components/Breadcrum/Breadcrum'
const PageSitemap = ({
    location,
    pageContext,
    data: {
        contentfulPageCustom,
        allContentfulPageCustom: {
            nodes
        }
    }
  }) => {
    const{heroImage, slug, seoTitle, seoDescription, breadcrum, pageTitle} = contentfulPageCustom
    const lang = useUrl()
  return (
    <section id= 'sitemap'>
      <Seo
        slug={slug}
        title={seoTitle}
        description={seoDescription && seoDescription.seoDescription}
        node_locale={pageContext.node_locale}
        lang={lang}
        hrefLangs={nodes}
        />
        {breadcrum && <Breadcrum data ={breadcrum} title = {pageTitle}/>}
        <HeroComponent data = {heroImage} />
        <SectionSitemap pageContext = {pageContext}/>
    </section>
  )
}

export const PageSitemapQuery = graphql`
    query PageSitemap($id:String!, $contentful_id: String! ){
        contentfulPageCustom(id:{eq:$id}){
            seoTitle
            seoDescription{
              seoDescription
            }
            node_locale
            slug
            breadcrum {
              title
              slug
            }
            pageTitle
            heroImage {
              title
              heroImage {
                gatsbyImageData
                title
              }
              heroImageMobile {
                gatsbyImageData
                title
              }
            }
        }
        allContentfulPageCustom(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}, slug: { ne: null }}){
            nodes {
                slug
                node_locale
            }
        }
    }
`
export default PageSitemap