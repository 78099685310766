import React from 'react'
import {Link} from 'gatsby'
import { useLocalPath } from '../hooks/useLocalPath';
import * as styles from './componentSitemap.module.scss'

const ComponentSitemap = ({data, heading}) => {
    let lists = data?.edges
    
  return (
    <div>
        <h2 className ={styles.heading}>{heading}</h2>
        <div className={styles.wrapper}>
        {lists?.map((item:any, id:number) =>{
            const {slug, title, productGrid, recipeGrid} = item.node
            if(productGrid){
                return(
                    <div className ={styles.subTitle} key ={id}>
                        <h3 className={styles.categoryTitle}>{title}</h3>
                        {productGrid?.products?.map((product, id) =>{
                            const{title, slug} = product
                            return(
                                <h3 className={styles.slug} key = {id}><Link to ={useLocalPath(slug)}>{title}</Link></h3>
                            )
                        })}
                    </div>
                )
            }
            if(recipeGrid){
                return(
                    <div className ={styles.subTitle} key= {id}>
                        {recipeGrid?.recipes?.map((recipe, id) =>{
                            const{title, slug} = recipe
                            return(
                                <h3 className={styles.slug} key = {id}><Link to ={useLocalPath(slug)}>{title}</Link></h3>
                            )
                        })}
                    </div>  
                )
            }
            return(
                <div key={id} className ={styles.title}>
                    <h2 className={styles.link}>{ title && <Link to = {useLocalPath(slug)}>{title}</Link>}</h2>
                </div>
            )
        })}
        </div>
    </div>
  )
}

export default ComponentSitemap