import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ComponentSitemap from "../ComponentSitemap/ComponentSitemap"
const SectionSitemap = ({pageContext}) => {
  const data = useStaticQuery(
    graphql`
      query {
        Articles: allContentfulPageArticle(filter: {slug: {ne: "tazo-product-blog"}}) {
            edges {
              node {
                node_locale
                slug
                title: thumbnailTitle
              }
            }
          }
        Recipes: allContentfulPageRecipesCategory {
            edges {
              node {
                node_locale
                title
                recipeGrid {
                  recipes {
                    title:thumbnailTitle
                    slug
                  }
                }
              }
            }
          }
        Products: allContentfulPageProductsCategory(filter: {slug: {ne: "products/"}}) {
          edges {
            node {
              node_locale
              title:thumbnailText
              productGrid {
                products {
                  title
                  slug
                }
              }
            }
          }
        }
        #Regenerative: allContentfulPageRegenerative {
        #  edges {
        #    node {
        #      node_locale
        #      title: thumbnailTitle
        #      slug
        #    }
        #  }
        #}
        Misc: allContentfulPageCustom {
          edges {
            node {
              node_locale
              slug
              title: pageTitle

            }
          }
        }
        ProductBlog: allContentfulPageProductBlog {
            edges {
              node {
                node_locale
                title: thumbnailTitle
                slug
              }
            }
          }
      }
    `
  )
  for (const key in data) {
    data[key].edges = data[key].edges.filter((edge) => edge.node.node_locale === pageContext.node_locale);
}
    // console.log(data, 'ss');
    
  const { Articles, Recipes, Products, Regenerative, Misc, ProductBlog } = data
  return (
    <div className="container container-lg">
        <div>
            <ComponentSitemap data={Articles} />
        </div>
        <div>
            <ComponentSitemap data={Misc} />
        </div>
        <div>
            <ComponentSitemap data={Regenerative} />
        </div>
        <div>
            <ComponentSitemap data={ProductBlog} heading = 'Product Blog'/>
        </div>
        <div>
            <ComponentSitemap data={Products} heading = 'All Products'/>
        </div>
        <div>
            <ComponentSitemap data={Recipes} heading = 'All Recipes'/>
        </div>
    </div>
  )
}

export default SectionSitemap
