// extracted by mini-css-extract-plugin
export var button = "componentSitemap-module--button--055ce";
export var cafeFormIntro = "componentSitemap-module--cafe-form-intro--90472";
export var categoryTitle = "componentSitemap-module--category-title--6fbe9";
export var heading = "componentSitemap-module--heading--6319c";
export var highlight = "componentSitemap-module--highlight--c843f";
export var link = "componentSitemap-module--link--4956d";
export var redButton = "componentSitemap-module--red-button--8c5c6";
export var redTransparentButton = "componentSitemap-module--red-transparent-button--f24b7";
export var slug = "componentSitemap-module--slug--b0144";
export var subTitle = "componentSitemap-module--sub-title--43700";
export var title = "componentSitemap-module--title--a9695";
export var transparentButton = "componentSitemap-module--transparent-button--5efb3";
export var wrapper = "componentSitemap-module--wrapper--90571";